<template>
    <div>
        <Header></Header>

        <div id="myCarousel" class="carousel slide">
            <div class="carousel-inner">
                <div class="item active">
                    <img src="./../assets/images/action_banner.png" alt="First slide" style="width:100%">
                </div>
            </div>
        </div>

        <section class="action_about_us">
            <div class="container">
                <div class="action_about_us_list">
                    <p><span>58</span>名</p>
                    <p>主创团队，知名导演，</p>
                    <p>制作人，摄影师</p>
                </div>
                <div class="action_about_us_list">
                    <p><span>200</span>场</p>
                    <p>成功策划过200多个</p>
                    <p>知名品牌</p>
                </div>
                <div class="action_about_us_list">
                    <p><span>300</span>部</p>
                    <p>拍摄制作超过300多部</p>
                    <p>影片作品</p>
                </div>
                <div class="action_about_us_list">
                    <p><span>180</span>个</p>
                    <p>累积服务过180家</p>
                    <p>国内外企业</p>
                </div>
            </div>
        </section>

        <section class="product_list_met_36_7_41 met-index-body best-room" m-id="41" id="showlist">
            <div class="container">

                <div class="title-main">
                    <img src="./../assets/images/production_logo.png" alt="First slide">
                </div>

                <div class="title-main product_title" style="display:flex;justify-content:space-around;padding:0 80px;">
                    <div :class="active_index === 1 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(1)">
                        <h3 :style="active_index === 1 ? 'color: #CE272D' : 'color: #37474f'">[企业宣传片]</h3>
                    </div>
                    <div :class="active_index === 2 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(2)">
                        <h3 :style="active_index === 2 ? 'color: #CE272D' : 'color: #37474f'">[动画]</h3>
                    </div>
                    <div :class="active_index === 3 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(3)">
                        <h3 :style="active_index === 3 ? 'color: #CE272D' : 'color: #37474f'">[纪录片]</h3>
                    </div>
                    <div :class="active_index === 4 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(4)">
                        <h3 :style="active_index === 4 ? 'color: #CE272D' : 'color: #37474f'">[TVC广告]</h3>
                    </div>
                    <div :class="active_index === 5 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(5)">
                        <h3 :style="active_index === 5 ? 'color: #CE272D' : 'color: #37474f'">[活动]</h3>
                    </div>
                    <div :class="active_index === 6 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(6)">
                        <h3 :style="active_index === 6 ? 'color: #CE272D' : 'color: #37474f'">[运营]</h3>
                    </div>
                </div>

                <div class="best-room-carousel">
                    <ul class="best-room_ul no-space imagesize index-product-list tab-pane active animation-scale-up row">
                        <li class="best-room_li col-lg-4 col-md-4 col-sm-12" v-for="(item, index) in video_list" :key="index">
                            <div class="best-room_img">
                                <img class="best-room_img_image" :src="item.cover_pic" alt="" style="object-fit:cover">
                                <div class="best-room_overlay" style="display: flex; flex-direction: column; justify-content: center;"
                                     @click="previewItem(item)">
                                    <div style="margin-left: 1.5rem; font-size: 16px; color: white; font-weight: bold">
                                        <div v-if="item.title" style="display: flex; flex-direction: row;">
                                            <div style="color: #33FF33">名称：</div>
                                            {{item.title}}
                                        </div>
                                        <div v-if="item.director.name" style="display: flex; flex-direction: row;">
                                            <div style="color: #33FF33">导演：</div>
                                            {{item.director.name}}
                                        </div>
                                        <div v-if="item.info" style="display: flex; flex-direction: row;">
                                            <div style="color: #33FF33">制作：</div>
                                            {{item.info}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <a class="btn btn-brand pill" title="业务领域" target="_self" v-if="show_more_button" @click="showMore">更多案例</a>

            </div>
        </section>

        <div class="pop" v-if="pop_close">
            <div class="close_btn" @click="pop_close = false">关闭</div>
            <div class="row">
                <div v-if="show_item.resource_type ===0 " class="poition_center col-lg-12 col-md-12 col-sm-12" v-html="show_item.video"></div>
                <div class="poition_center col-lg-12 col-md-12 col-sm-12" v-else>
                    <img :src="show_item.cover_pic" alt style>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        pop_close: false,
        active_index: 5,
        show_item: {},
        video_list: [],
        show_more_button: true,
        all_videios: {tvc: [], trailer: [], cartoon: [], documentary: [], activity: [], operation: []},
      }
    },
    mounted() {
      this.getResources();
    },
    methods: {
      previewItem(item) {
        this.pop_close = true;
        this.show_item = item;
      },
      getResources() {
        this.$api.getResources().then(res => {
          let videos = res.data.data;
          for (let i = 0; i < videos.length; i++) {
            this.all_videios[videos[i].category].push(videos[i]);
            this.video_list = this.all_videios.activity.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.activity.length;
          }
        })
      },
      to_tab(index) {
        switch (index) {
          case 1:
            this.active_index = 1;
            this.video_list = this.all_videios.trailer.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.trailer.length;
            break;
          case 2:
            this.active_index = 2;
            this.video_list = this.all_videios.cartoon.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.cartoon.length;
            break;
          case 3:
            this.active_index = 3;
            this.video_list = this.all_videios.documentary.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.documentary.length;
            break;
          case 4:
            this.active_index = 4;
            this.video_list = this.all_videios.tvc.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.tvc.length;
            break;
          case 5:
            this.active_index = 5;
            this.video_list = this.all_videios.activity.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.activity.length;
            break;
          case 6:
            this.active_index = 6;
            this.video_list = this.all_videios.operation.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.operation.length;
            break;
        }
      },
      showMore() {
        switch (this.active_index) {
          case 1:
            this.video_list = this.all_videios.trailer;
            break;
          case 2:
            this.video_list = this.all_videios.cartoon;
            break;
          case 3:
            this.video_list = this.all_videios.documentary;
            break;
          case 4:
            this.video_list = this.all_videios.tvc;
            break;
          case 5:
            this.video_list = this.all_videios.activity;
            break;
          case 6:
            this.video_list = this.all_videios.operation;
            break;
        }
        this.show_more_button = false;
      }
    }
  }
</script>

<style scoped>
    @import "../assets/css/basic.css";
    @import "../assets/css/index_cn.css";
    @import "../assets/css/show_cn.css";

    .carousel {
        margin-top: 70px !important;
    }

    .mt_10 {
        margin-bottom: 20px;
    }

    .pop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 3000;
    }

    .product_h3_selected {
        transform: scale(1.2);
        cursor: pointer;
    }

    .product_h3_normal {
        cursor: pointer;
    }

    .close_btn {
        color: #fff;
        font-size: 14px;
        float: right;
        margin: 40px;
        cursor: pointer;
    }

    .poition_center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .product_list_met_36_7_41 .best-room_img img {
        height: 200px;
    }

    .best-room_img {
        overflow: hidden;
    }

    .best-room_img img {
        height: 200px;
        transition: all .4s;
        -moz-transition: all .4s;
        -webkit-transition: all .4s;
        -o-transition: all .4s;
    }

    @media (max-width: 767px) {
        /deep/ iframe {
            width: 400px !important;
            height: 200px !important;
        }
    }
</style>
